import styles from '../../styles/modules/UniswapLink.module.css'
import { useState, Fragment, useEffect } from 'react'
import { useSelector } from 'react-redux';
import {selectBaseToken, selectPool, selectQuoteToken} from '../../store/pool';
import { selectSelectedEditableStrategyRanges } from '../../store/strategyRanges';

import { selectProtocol } from '../../store/protocol';

const UniswapLink = (props) => {

  const pool = useSelector(selectPool);
  const strategyRanges = useSelector(selectSelectedEditableStrategyRanges);
  const protocol = useSelector(selectProtocol);
  const [uniUrl, setUniUrl] = useState();
  const baseToken = useSelector(selectBaseToken);
  const quoteToken = useSelector(selectQuoteToken);

  useEffect(() => {
    if (pool && pool.token0) {


      // console.log(pool)
      // console.log(strategyRanges)
      // console.log(protocol)
      const t0 = quoteToken.tokenId;
      const t1 = baseToken.tokenId;
      const feeTier = pool.feeTier
      const prot = protocol.chain;
      const min = strategyRanges[0].inputs.min.value;
      const max = strategyRanges[0].inputs.max.value;

      const url = 'https://quickswap.exchange/#/pools?currency0=' + t0 + '&currency1=' + t1;
      // console.log(url)
      //0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/500?chain=polygon&minPrice=1713&maxPrice=1775.8`;
      setUniUrl(url)
    }
  }, [pool, protocol, strategyRanges, baseToken, quoteToken.id]);


  return (
    <Fragment>
      <div className={styles['uniswap-link-container']}>
        <button onClick={() =>  window.open(uniUrl)}>Provide Liquidity</button>
      </div>
  </Fragment>)
}


export default UniswapLink